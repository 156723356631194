// const authToken =
//   process.env.REACT_APP_PUBLIC_TOKEN || '097e1ac1-aa7d-4678-aec5-ab5083b551f7';
const orgId =
  process.env.REACT_APP_ORG_ID || '7a6c31ed-73a1-49de-8c93-51e2b91dc762';

export default {
  // Admin
  // authToken: '9378a658-b95f-4acc-a715-75cbcd535b04',
  // Staging
  // authToken: 'f026609b-7611-4a0a-aae0-8f36e3208393',
  // Local
  
  authToken: window.location.hostname == 'msa-phase-2.herokuapp.com' ? 'dd4c07cd-f7e0-4668-b52d-e9f54b3decb2' : '671616b6-79d9-4efe-80e8-d533149531e1',  
  assetId: "6587dc46-a580-47ae-b75c-c82e6baf578a",
  orgId,
};
