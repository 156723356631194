import React, { useState, useRef, useEffect, useContext } from 'react';
import { Accordion2 as Accordion } from '../../Controllers';
import Player from '../SharedComponents/Player';
import MenuBar from '../SharedComponents/MenuBar';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Wrapper,
  PlayerWrapper,
  Title,
  Subtitle,
} from './accordionTemplate2.styles';
import { useForm } from "react-hook-form";
import inputsA from "../../../config/athletix/inputs";
import inputsG from "../../../config/gXtreme/inputs";
import { pdfService } from "../../../toolkit/toolkit/pdfService"
import toolkit from '../../toolkit';
import Modal from "react-modal";
import globeLockupLogo from '../../../assets/GLOBE_LOCKUP_LOGO-R.png';
import { SecondaryBar, SecondaryBarItem } from '../SharedComponents/MenuBar/menuBar.styles';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { AppContext } from '../../../provider/provider';
// import { getDataTable } from '../../toolkit/customServices.js';
import axios from 'axios';
import { isForwardRef } from 'react-is';
import { saveConfiguration, addNewConfigurationInDataTable } from '../../toolkit/customServices.js';
import threekitConfigA from '../../../config/athletix/threekitConfig';
import threekitConfigG from '../../../config/gXtreme/threekitConfig';

let confList = [];
let confListFiltered = [];
const threekitConfig = window.location.pathname === "/athletix" ? threekitConfigA : threekitConfigG;
const HOST = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

const AccordionTemplate2 = ({
  title,
  subtitle,
  controller,
  sections,
  configuration,
  handleSetAttribute
}) => {
  const [activeAddress, setActiveAddress] = useState([0, 0]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [configurationListModalIsOpen, setConfigurationListModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [buttonsConfiguration, setButtonsConfiguration] = useState(false);
  const [restDataTable, setRestDataTable] = useState(null);
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [configurationList, setConfigurationList] = useState("");
  const [configurationName, setConfigurationName] = useState("");
  const [configurationNameModal, setConfigurationNameModal] = useState(true);

  const override = css`
  margin-top:16rem;
  justify-content:center;
`;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    }
  };

  const { state, setState } = useContext(AppContext);

  const ref = useRef(null);
  const inputs = window.location.pathname === "/athletix" ? inputsA : inputsG;
  let newConfiguration = [];
  let lettering = [];
  let letteringConfiguration = [];
  let letteringJsonA = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, letteringArcheting: null, sortValue: 1 };
  let letteringJsonB = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, letteringArcheting: null, sortValue: 2 };
  let letteringJsonC = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, sortValue: 3 };
  let letteringJsonD = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, letteringArcheting: null, sortValue: 4 };
  let letteringJsonE = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, letteringArcheting: null, sortValue: 5 };
  let letteringJsonF = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, sortValue: 6 };
  let letteringJsonPatch = { letteringGroup: null, letteringText: null, letteringSize: null, letteringColor: null, sortValue: 7 };
  let group = '';
  let number = '';
  let label = '';
  let type = '';
  let sortValue = '';
  let groupTitle = '';
  let gear = '';
  let html = [];
  const currentProduct = window.location.pathname == "/athletix" ? "Athletix" : "GX3";
  let productSelected = sessionStorage.getItem('gearSelected');
  let gearSelected = localStorage.getItem('gearSelected');
  const needRefresh = localStorage.getItem('needRefresh');
  let gearTitle = '';
  let gearHeaderClass = '';
  let gearHeaderTitle = '';
  let existSections = [];
  let isGXtreme = window.location.pathname === "/gXtreme";

  useEffect(() => {
    if (controller?.[activeAddress[0]]?.sections?.[activeAddress[1]].label !== "SAVE CONFIGURATION") {
      setConfigurationNameModal(true);
    };
    let elem = document.querySelectorAll("#root .summary .summary-wrapper .configuration-wrapper .group") ? document.querySelectorAll("#root .summary.configuration .summary-wrapper .configuration-wrapper .group") : null
    elem = elem.length > 0 ? elem[(elem.length - 1)].style.display = "none" : null
  })

  useEffect(() => {
      if(needRefresh === 'true') {
        localStorage.setItem('needRefresh', 'false');
        window.location.reload();
      } else {
        triggerEventClick();
      }
    // } 
  }, [window.threekitApi]);

  useEffect(() => {
    //if(localStorage.getItem('isConfigurationApplied_' + currentProduct) === "false") {
      setConfigurationList(confList);
      if(configurationList.length > 0) {
        setModalMessage('Do you want to load a previous configuration?')
        setButtonsConfiguration(true)
      }
    //}
  })

  const triggerEventClick = async () => {
    await toolkit.player.getConfigurationOutput().then((configuration) => {
      if(configuration) {
        const clickElement =  configuration.GEAR_VISIBILITY ? (
          configuration.GEAR_VISIBILITY.value === 'Athletix' ? 'gear-athletix' :
          configuration.GEAR_VISIBILITY.value === 'Jacket' ? 'gear-jacket' : 
          configuration.GEAR_VISIBILITY.value === 'Pants' ? 'gear-pants' : 
          configuration.GEAR_VISIBILITY.value === 'GX3' ? 'gear-gx3' : 
          configuration.GEAR_VISIBILITY.value === 'GX3 Coat' ? 'gear-gx3-coat' : 
          'gear-gps-pant') : '';
        if(document.getElementById(clickElement)) {
          document.getElementById(clickElement).click()
        }
      } 
    });  
  };

  const { register, handleSubmit, watch, errors } = useForm();
  watch('productQuantityJacket')
  const onSubmit = async data => {
    data.specifications = newConfiguration;
    data.letterings = letteringConfiguration;
    data.logo = 'https://cdn1-originals.webdamdb.com/12943_89215264?cache=1554208451&response-content-disposition=inline;filename%253DGLOBE_LOCKUP_LOGO-01.png&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cCo6Ly9jZG4xLW9yaWdpbmFscy53ZWJkYW1kYi5jb20vMTI5NDNfODkyMTUyNjQ~Y2FjaGU9MTU1NDIwODQ1MSZyZXNwb25zZS1jb250ZW50LWRpc3Bvc2l0aW9uPWlubGluZTtmaWxlbmFtZSUyNTNER0xPQkVfTE9DS1VQX0xPR08tMDEucG5nIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoyMTQ3NDE0NDAwfX19XX0_&Signature=VjnUQBtdTWRlZ~h4nxbo0rbD~d2HfL8rkt2VpA~TggZu-gjMmFso5c8yKyF3gSGADzD7jkty~OhUDJXKwbWifrtKtsC5GLPvsR6-t33vrJlDYhulFSr2r0F2Lupn1mPU4yb41ohLbzOWB9qorwSoJjtcAiuMN0c-ZIdyMAxscWdHfvz~KyUThv--hHu7X-mmHIYp--Q4e1Mc7MYNu-gTJsithomFSFIZGemBHMF3v6wDA1e-XIJRt-EGM47h-IafkgGPUrIKyRIq73zEnii5DRaBVNiYSVuaBk37BBiRX-delvYQa~XXTaHVjC-vMlE290Y0wln~1Kbuqd2tiq1qDw__&Key-Pair-Id=APKAI2ASI2IOLRFF2RHA';
    let snapshotModel = gearSelected === 'Athletix' ? await toolkit.player.getSnapshot({ GEAR_VISIBILITY: "Athletix" }) : gearSelected === 'GX3' ? await toolkit.player.getSnapshot({ GEAR_VISIBILITY: "GX3" }) :
      gearSelected === 'Jacket' ? await toolkit.player.getSnapshot({ GEAR_VISIBILITY: "Jacket" }) : gearSelected === 'GX3 Coat' ? await toolkit.player.getSnapshot({ GEAR_VISIBILITY: "GX3 Coat" }) :
        gearSelected === 'Pants' ? await toolkit.player.getSnapshot({ GEAR_VISIBILITY: "Pants" }) : await toolkit.player.getSnapshot({ GEAR_VISIBILITY: "GPS Pant" })
    document.getElementById("loader-container").style.display = "none";
    (await pdfService(data, snapshotModel, gearSelected) === true) ? openModal(true) : openModal(false);
  }
  
  if (configuration !== null) {
    for (var key in configuration) {
      group = '';
      number = '';
      type = '';
      label = '';
      gear = '';
      sortValue = '';
      let sortGroup = { 'NA': 1, CLOS: 2, GENERIC: 3, HOLDER: 4, LTRG: 5, MISCELLANEOUS: 6, PATCH: 7, PCKT: 8, REINFORCEMENT: 9, SUSPENDER: 10, SLVW: 11, TRIM: 12 }

      let sortByGearGroup = window.location.pathname === "/athletix" ? { 'athletix': 1, 'Jacket': 2, 'Pants': 3 } : { 'GX3': 1, 'Jacket': 2, 'Pants': 3 }
      if (key.includes('Row') || key.includes('Patch Lettering')) {
        lettering.push({ key: key, description: configuration[key] });
      }

      if (configuration[key] !== '') {
        for (var keyInput in inputs) {
          if (inputs[keyInput].attribute === key && !key.includes('Row')) {
            if (window.location.pathname == "/gXtreme" && inputs[keyInput].attribute == "GEAR_VISIBILITY") {
              configuration[key] = sessionStorage.getItem("suitPieceSelected") ? sessionStorage.getItem("suitPieceSelected") : "GX3"
            }
            group = inputs[keyInput].optionGroup[configuration[key]];
            number = inputs[keyInput].optionNumber[configuration[key]];
            type = inputs[keyInput].optionType[configuration[key]];
            label = inputs[keyInput].label[configuration[key]];
            gear = inputs[keyInput].gear;
            sortValue = inputs[keyInput].sortValue;
          }
        }
        if (configuration[key].assetId !== undefined && configuration[key].assetId !== '' && !key.includes('Row') && !key.includes('Patch Lettering') && (productSelected === gear || gear === 'athletix' || gear === 'GX3')) {
          newConfiguration.push({
            optionNumber: number,
            optionGroup: group,
            optionDescription: configuration[key].assetId,
            optionType: type,
            attribute: key,
            label: label,
            sortValue: sortValue,
            gear: gear,
            sortByGear: sortByGearGroup[gear]
          })

        } else if (configuration[key].assetId === undefined && !key.includes('Row') && !key.includes('Patch Lettering') && configuration[key] !== "None" && configuration[key] !== "NONE" && (productSelected === gear || gear === 'athletix' || productSelected === 'Athletix' || gear === 'GX3' || productSelected === 'GX3')) {
          newConfiguration.push({
            optionNumber: number,
            optionGroup: group,
            optionDescription: configuration[key],
            optionType: type,
            attribute: key,
            label: label,
            sortValue: sortValue,
            gear: gear,
            sortByGear: sortByGearGroup[gear]
          })
        }
      }
    }
  }

  const processLettering = (letteringJson) => {
    let complete = true;
    for (var key in letteringJson) {
      if (isGXtreme) {
        if (letteringJson[key] === null) {
          complete = false;
        }
      }
      else {
        if (letteringJson[key] === null && key !== 'letteringArcheting') {
          complete = false;
        }
      }
    }
    if (complete === true) {
      letteringJson.letteringText !== '' && letteringConfiguration.push(letteringJson);
    }
  }

  if (lettering.length !== 0) {
    for (var i = 0; i < lettering.length; i++) {
      if (lettering[i] !== null) {
        let complete = true;
        switch (lettering[i].key.includes('Row A') ? 'A' : lettering[i].key.includes('Row B') ? 'B' : lettering[i].key.includes('Row C') ? 'C' : lettering[i].key.includes('Row D') ? 'D' : lettering[i].key.includes('Row E') ? 'E' : lettering[i].key.includes('Row F') ? 'F' : lettering[i].key.includes('Patch Lettering') ? 'Patch Lettering' : '') {
          case 'A':
            letteringJsonA.letteringGroup === null ? letteringJsonA.letteringGroup = 'Row A' : letteringJsonA.letteringGroup = letteringJsonA.letteringGroup;
            letteringJsonA.letteringText === null ? lettering[i].key.includes('Lettering') ? letteringJsonA.letteringText = lettering[i].description : letteringJsonA.letteringText = null : letteringJsonA.letteringText = letteringJsonA.letteringText;
            letteringJsonA.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonA.letteringSize = lettering[i].description : letteringJsonA.letteringSize = null : letteringJsonA.letteringSize = letteringJsonA.letteringSize;
            letteringJsonA.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonA.letteringColor = lettering[i].description : letteringJsonA.letteringColor = null : letteringJsonA.letteringColor = letteringJsonA.letteringColor;
            letteringJsonA.letteringArcheting === null ? lettering[i].key.includes('Archeting') ? letteringJsonA.letteringArcheting = lettering[i].description : letteringJsonA.letteringArcheting = null : letteringJsonA.letteringArcheting = letteringJsonA.letteringArcheting;

            processLettering(letteringJsonA);
            break;
          case 'B':
            letteringJsonB.letteringGroup === null ? letteringJsonB.letteringGroup = 'Row B' : letteringJsonB.letteringGroup = letteringJsonB.letteringGroup;
            letteringJsonB.letteringText === null ? lettering[i].key.includes('Lettering') ? letteringJsonB.letteringText = lettering[i].description : letteringJsonB.letteringText = null : letteringJsonB.letteringText = letteringJsonB.letteringText;
            letteringJsonB.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonB.letteringSize = lettering[i].description : letteringJsonB.letteringSize = null : letteringJsonB.letteringSize = letteringJsonB.letteringSize;
            letteringJsonB.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonB.letteringColor = lettering[i].description : letteringJsonB.letteringColor = null : letteringJsonB.letteringColor = letteringJsonB.letteringColor;
            letteringJsonB.letteringArcheting === null ? lettering[i].key.includes('Archeting') ? letteringJsonB.letteringArcheting = lettering[i].description : letteringJsonB.letteringArcheting = null : letteringJsonB.letteringArcheting = letteringJsonB.letteringArcheting;

            processLettering(letteringJsonB);
            break;
          case 'C':
            letteringJsonC.letteringGroup === null ? letteringJsonC.letteringGroup = 'Row C' : letteringJsonC.letteringGroup = letteringJsonC.letteringGroup;
            letteringJsonC.letteringText === null ? lettering[i].key.includes('Lettering') ? letteringJsonC.letteringText = lettering[i].description : letteringJsonC.letteringText = null : letteringJsonC.letteringText = letteringJsonC.letteringText;
            letteringJsonC.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonC.letteringSize = lettering[i].description : letteringJsonC.letteringSize = null : letteringJsonC.letteringSize = letteringJsonC.letteringSize;
            letteringJsonC.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonC.letteringColor = lettering[i].description : letteringJsonC.letteringColor = null : letteringJsonC.letteringColor = letteringJsonC.letteringColor;
            if (isGXtreme) {
              letteringJsonC.letteringArcheting = 'None';
            }

            for (var key in letteringJsonC) {
              if (letteringJsonC[key] === null) {
                complete = false;
              }
            }
            if (complete === true) {
              letteringJsonC.letteringText !== '' && letteringConfiguration.push(letteringJsonC);
            }
            break;
          case 'D':
            letteringJsonD.letteringGroup === null ? letteringJsonD.letteringGroup = 'Row D' : letteringJsonD.letteringGroup = letteringJsonD.letteringGroup;
            letteringJsonD.letteringText === null ? lettering[i].key.includes('Lettering') ? letteringJsonD.letteringText = lettering[i].description : letteringJsonD.letteringText = null : letteringJsonD.letteringText = letteringJsonD.letteringText;
            letteringJsonD.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonD.letteringSize = lettering[i].description : letteringJsonD.letteringSize = null : letteringJsonD.letteringSize = letteringJsonD.letteringSize;
            letteringJsonD.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonD.letteringColor = lettering[i].description : letteringJsonD.letteringColor = null : letteringJsonD.letteringColor = letteringJsonD.letteringColor;
            letteringJsonD.letteringArcheting === null ? lettering[i].key.includes('Archeting') ? letteringJsonD.letteringArcheting = lettering[i].description : letteringJsonD.letteringArcheting = null : letteringJsonD.letteringArcheting = letteringJsonD.letteringArcheting;

            processLettering(letteringJsonD);
            break;
          case 'E':
            letteringJsonE.letteringGroup === null ? letteringJsonE.letteringGroup = 'Row E' : letteringJsonE.letteringGroup = letteringJsonE.letteringGroup;
            letteringJsonE.letteringText === null ? lettering[i].key.includes('Lettering') ? letteringJsonE.letteringText = lettering[i].description : letteringJsonE.letteringText = null : letteringJsonE.letteringText = letteringJsonE.letteringText;
            letteringJsonE.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonE.letteringSize = lettering[i].description : letteringJsonE.letteringSize = null : letteringJsonE.letteringSize = letteringJsonE.letteringSize;
            letteringJsonE.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonE.letteringColor = lettering[i].description : letteringJsonE.letteringColor = null : letteringJsonE.letteringColor = letteringJsonE.letteringColor;
            letteringJsonE.letteringArcheting === null ? lettering[i].key.includes('Archeting') ? letteringJsonE.letteringArcheting = lettering[i].description : letteringJsonE.letteringArcheting = null : letteringJsonE.letteringArcheting = letteringJsonE.letteringArcheting;

            processLettering(letteringJsonE);
            break;
          case 'F':
            letteringJsonF.letteringGroup === null ? letteringJsonF.letteringGroup = 'Row F' : letteringJsonF.letteringGroup = letteringJsonF.letteringGroup;
            letteringJsonF.letteringText === null ? lettering[i].key.includes('Lettering') ? letteringJsonF.letteringText = lettering[i].description : letteringJsonF.letteringText = null : letteringJsonF.letteringText = letteringJsonF.letteringText;
            letteringJsonF.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonF.letteringSize = lettering[i].description : letteringJsonF.letteringSize = null : letteringJsonF.letteringSize = letteringJsonF.letteringSize;
            letteringJsonF.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonF.letteringColor = lettering[i].description : letteringJsonF.letteringColor = null : letteringJsonF.letteringColor = letteringJsonF.letteringColor;
            if (isGXtreme) {
              letteringJsonC.letteringArcheting = 'None';
            }
            for (var key in letteringJsonF) {
              if (letteringJsonF[key] === null) {
                complete = false;
              }
            }
            if (complete === true) {
              letteringJsonF.letteringText !== '' && letteringConfiguration.push(letteringJsonF);
            }
            break;
          case 'Patch Lettering':
            letteringJsonPatch.letteringGroup === null ? letteringJsonPatch.letteringGroup = 'Patch Lettering' : letteringJsonPatch.letteringGroup = letteringJsonPatch.letteringGroup;
            letteringJsonPatch.letteringText === null ? lettering[i].key.includes('Lettering Input') ? letteringJsonPatch.letteringText = lettering[i].description : letteringJsonPatch.letteringText = null : letteringJsonPatch.letteringText = letteringJsonPatch.letteringText;
            letteringJsonPatch.letteringSize === null ? lettering[i].key.includes('Size') ? letteringJsonPatch.letteringSize = lettering[i].description : letteringJsonPatch.letteringSize = null : letteringJsonPatch.letteringSize = letteringJsonPatch.letteringSize;
            letteringJsonPatch.letteringColor === null ? lettering[i].key.includes('Color') ? letteringJsonPatch.letteringColor = lettering[i].description : letteringJsonPatch.letteringColor = null : letteringJsonPatch.letteringColor = letteringJsonPatch.letteringColor;
            if (isGXtreme) {
              letteringJsonC.letteringArcheting = 'None';
            }
            for (var key in letteringJsonPatch) {
              if (letteringJsonPatch[key] === null) {
                complete = false;
              }
            }
            if (complete === true) {
              letteringJsonPatch.letteringText !== '' && letteringConfiguration.push(letteringJsonPatch);
            }
            break;
          default:
          // unknown type! based on the language,
          // there should probably be some error-handling
          // here, maybe an exception
        }
      };
    }

  }

  // newConfiguration.sort(((a, b) => a.sortValue - b.sortValue));
  let secondaryOptions;
  // newConfiguration.sort(((a, b) => a.gear - b.gear));

  if (controller?.[activeAddress[0]]?.sections) {
    secondaryOptions = controller[activeAddress[0]].sections.map(
      ({ label }) => label
    );
  }

  let menuOption = controller?.[activeAddress[0]] && controller[activeAddress[0]].label;
  let subMenuOption = controller?.[activeAddress[0]]?.sections?.[activeAddress[1]] && controller[activeAddress[0]]?.sections?.[activeAddress[1]].label.toLowerCase().replace(" ", "-");
  let extraClass = `${menuOption && menuOption.toLowerCase().replace(" ", "-")} ${subMenuOption && subMenuOption.toLowerCase().replace(" ", "-")}`;


  let controllerOnView = controller.length !== 0 ? controller[activeAddress[0]].label : '';

  const configurationSection = (itemGroup) => {
    html = [];
    if (itemGroup !== null && ifExistSection(itemGroup, gearTitle) === false) {
      for (var i = 0; i < newConfiguration.length; i++) {
        if (newConfiguration[i].optionGroup === itemGroup && newConfiguration[i].gear === gearTitle && newConfiguration[i].attribute !== 'ACTIVE_CAMERA') {
          html.push(<tr key={i} className='group-content-row configuration-table-tr'>
            <td className='configuration-table-optionNumber'>{configureName(newConfiguration[i].attribute.replaceAll('_', ' ').toLowerCase())}</td>
            {/*<td className='configuration-table-optionGroup'>{newConfiguration[i].optionGroup}</td>*/}
            <td className='configuration-table-optionGroup'>{newConfiguration[i].optionType}</td>
            <td className='configuration-table-optionDescription'>{newConfiguration[i].optionDescription}</td>
          </tr>)
        }
        existSections.push({ group: itemGroup, gear: gearTitle });
        groupTitle = itemGroup;
      }
    };
  }

  const configureName = (currentName) => {
    switch (currentName) {
      case 'gear visibility':
        return 'gear';
      case 'jacket front stormflap closure':
        return 'Closure';
      case 'jacket front stormflap zipper pull':
        return 'Zipper Pull';
      case 'front holders':
        return 'Holder - Stormflap';
      case 'antenna notch':
        return 'Right Antenna Notch';
      case 'front holders above trim':
        return 'Holder - Above Trim';
      case 'right pockets':
        return 'Right Pockets';
      case 'right chest mic':
        return 'Holder Michrophone';
      case 'left chest mic':
        return 'Holder Michrophone';
      case 'right chest holders stormflap':
        return 'Holder';
      case 'right front next to stormflap':
        return 'Holder';
      case 'left front next to stormflap':
        return 'Holder';
      case 'right embroidery padding':
        return 'Patch Padding';
      case 'left embroidery padding':
        return 'Patch Padding';
      case 'right embroidery patches':
        return 'Custom Patches';
      case 'left embroidery patches':
        return 'Custom Patches';
      case 'right flags':
        return 'Flags';
      case 'left flags':
        return 'Flags';
      case 'pant front frontclosure belt loops':
        return 'Belt Loops';
      case 'pant front frontclosure belt':
        return 'Belt';
      case 'pant front frontclosure carabiner':
        return 'Carabiner';
      case 'pant front frontclosure rappelling loops':
        return 'Rappelling Loops';
      case 'pant side pockets right size type':
        return 'Size & Type';
      case 'pant side pockets left size type':
        return 'Size & Type';
      case 'pant side pockets right inside reinforcement':
        return 'Inside Reinforcement';
      case 'pant side pockets left inside reinforcement':
        return 'Inside Reinforcement';
      case 'pant side pockets left outside reinforcement':
        return 'Outside Reinforcement';
      case 'pant side pockets right outsite reinforcement':
        return 'Outside Reinforcement';
      case 'pant side pockets right pull tab':
        return 'Pull Tab';
      case 'pant side pockets left pull tab':
        return 'Pull Tab';
      case 'pant side pockets right pocket divider':
        return 'Pocket Divider';
      case 'pant side pockets left pocket divider':
        return 'Pocket Divider';
      case 'pant side pockets right tool compartment':
        return 'Tool Compartment';
      case 'pant side pockets left tool compartment':
        return 'Tool Compartment';
      case 'pant other pants cuff':
        return 'Pant Cuffs';
      case 'pant other knees reinforcement':
        return 'Knees Reinforcements';
      case 'pant other knees padding lining':
        return 'Knees Padding - Lining';
      case 'pant other globeguard':
        return 'Globe Guard';
      case 'pant other suspenders':
        return 'Suspenders';
      case 'pant other mandatory cchr':
        return 'Mandatory CCHR';
      default:
        return currentName;
    }
  }

  const ifExistSection = (group, gear) => {
    for (var i = 0; i < existSections.length; i++) {
      if (existSections[i].group === group && existSections[i].gear === gear && existSections[i].group === '') {
        return true;
      }
    }
    return false;
  };

  const gearHeader = (item) => {
    gear = '';
    if (item !== null) {
      if (gearTitle === '') {
        gear = item.gear;
      } else if (item.gear !== gearTitle) {
        gear = item.gear;
        gearHeaderClass = gear;
      }
      gearTitle = item.gear;
    }
    gearHeaderTitle = gear;
  };

  const ucword = (str) => {
    str = str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (replace_latter) {
      return replace_latter.toUpperCase();
    });  //Can use also /\b[a-z]/g
    return str;  //First letter capital in each word
  }

  const openModal = (typeOfMessage) => {
    // typeOfMessage === true ? setModalMessage('Your Configuration has been sent successfully!')  : setModalMessage( 'The mail could not be sent !!');
    setModalMessage('Your Configuration has been sent successfully!')
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setButtonsConfiguration(false);
    setConfigurationListModalIsOpen(false);
  };

  const closeConfigurationModal = () => {
    setButtonsConfiguration(false);
    setConfigurationListModalIsOpen(false);
  };

  const loadConfiguration = (configId) => {
    const shortId = configId;
    localStorage.setItem('loadConfigurationList', 'false');
    localStorage.setItem(`shortId_${currentProduct}`, shortId);
    setConfigurationListModalIsOpen(false);
    setButtonsConfiguration(false);
    setConfigurationLoaded(true);
    // localStorage.setItem('isConfigurationApplied_'+currentProduct, "true");

    if (shortId !== null && shortId !== "" && shortId !== undefined) {
      const url = window.location.origin + window.location.pathname;
      const newUrl = url + '?tkcsid=' + shortId
      window.location.href = newUrl;
    }
  };

  const currentYear = new Date;
  const isTheElemSelectedFunc = (subHeader, primaryMenu) => {
    if (subHeader !== 'CONFIGURATION' && subHeader !== 'LETTERING' && subHeader !== "SHARE VIA EMAIL" && subHeader !== "SAVE CONFIGURATION" && subHeader !== "Materials" && primaryMenu !== "summary") {
      if (primaryMenu == "G Xtreme" || state.product == 'GX3' && !primaryMenu) { primaryMenu = 'GX3' }
      if (primaryMenu == 'athletix ™' || state.product == 'Athletix' && !primaryMenu) { primaryMenu = 'Athletix' }
      return state.subMenuSelections[primaryMenu][subHeader];
    }
  }
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const zoomAndControllers = (zoomlevel) => {
    window.threekitApi.api.camera.zoom(zoomlevel);
  }

  newConfiguration.sort(((a, b) => a.sortValue - b.sortValue));
  letteringConfiguration.sort(((a, b) => a.sortValue - b.sortValue));
  const setDomElementToSelected = (e, primaryMenus, label) => {
    if (label !== 'CONFIGURATION' && label !== 'LETTERING' && label !== "SHARE VIA EMAIL" && label !== "SAVE CONFIGURATION") {
      if (primaryMenus == 'G Xtreme' || state.product == 'GX3' && !primaryMenus) { primaryMenus = 'GX3' }
      if (primaryMenus == 'athletix ™' || state.product == 'Athletix' && !primaryMenus) { primaryMenus = 'Athletix' }


      state.subMenus = label;
      state.subMenuSelections[primaryMenus][label] = true;
      e.target.classList.add('visited');
    }
  }

  const configurationNameInputChange = (e) => {
    setConfigurationName(e.target.value);
  };

  const configurationNameCloseModal = () => {
    setConfigurationNameModal(false);
  };

  const handleSearchConfiguration = (e) => {
    let PATTERN = e.target.value.toUpperCase();
    confList = confListFiltered.filter(function (obj) { return obj.value['Configuration Name'].toUpperCase().indexOf(PATTERN) !== -1; });
    setConfigurationList(confList);
  }

  const saveConfigurationName = async () => {
    if (configurationName !== "") {
      let currentConfiguration = {};
      await toolkit.player.getConfigurationOutput().then((configuration) => {
        if (configuration) {
          for (const property in configuration) {
            currentConfiguration[`${property}`] = configuration[property].value;
          }
        }
        const bodySaveConfiguration = {
          "orgId": threekitConfig.orgId,
          "productId": threekitConfig.assetId,
          "productVersion": 'v1.0',
          "variant": currentConfiguration,
          "metadata": {},
          "files": "string",
          "shortId": "",
          "customerId": ""
        };
  
        saveConfiguration(bodySaveConfiguration);
  
        const user = JSON.parse(localStorage.getItem('user'));
        const shortId = localStorage.getItem(`shortId_${currentProduct}`);
        const bodySaveConfigurationDataTable = {
          "Username": user.Username,
          "Email": user["Email Address"],
          "ShortId": shortId,
          "GearSelected": currentProduct,
          "ConfigurationName": configurationName.toUpperCase()
        };
        addNewConfigurationInDataTable(bodySaveConfigurationDataTable)
        setConfigurationNameModal(false);
      })
    }
  }

  return (
    <div id="mainContainer" className="container">
      <header>
        <img src={globeLockupLogo} className="header-logo" alt="" />
        <MenuBar
          primaryOptions={controller.map(({ label }) => label)}
          primarySelected={activeAddress[0]}
          secondaryOptions={secondaryOptions ? secondaryOptions : ""}
          secondarySelected={activeAddress[1]}
          handleClickPrimary={val => setActiveAddress([val, 0])}
          handleClickSecondary={val => setActiveAddress([activeAddress[0], val])}
          handleSetAttribute={handleSetAttribute}
        />
      </header>
      <div style={{ display: "flex", width: "100%" }}>
        <div id="loader-container">
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

        <Wrapper id='player-container' className={`player-container ` + extraClass}>
          <PlayerWrapper className='three-d-player-space'>
            <Player />
            <div className='disclaimer'>
              Images displayed are for illustration purposes only. Actual product may vary. &nbsp;
              <a target='blank' href="https://globe.msasafety.com/configurator/support">
                Learn more
              </a>
            </div>
            {window.threekitApi ?
              <div id='zoom-wrapper' className='zoom-wrapper'>
                <button style={{ borderRadius: '5px', border: '0', backgroundColor: "#FFF", margin: '5px' }} onClick={() => zoomAndControllers(2)}><PlusOutlined style={{ borderRadius: '5px', fontSize: '22px', color: 'grey', padding: '5px 2px 5px 2px' }} /></button>
                <button style={{ borderRadius: '5px', border: '0', backgroundColor: "#FFF", margin: '5px' }} onClick={() => zoomAndControllers(-2)}><MinusOutlined style={{ borderRadius: '5px', fontSize: '22px', color: 'grey', padding: '5px 2px 5px 2px' }} /></button><br></br>            </div>

              : null}
          </PlayerWrapper>

          {window.threekitApi ?
            <div className="container" style={{ marginBottom: "40px", position: "relative", paddingLeft: "4%", paddingRight: "4%" }}>
              <div className="nav--subheaders" style={{ display: "flex", marginLeft: "4%", position: 'relative' }}>
                {secondaryOptions && (secondaryOptions.length > 3) &&
                  <div className='buttonBar leftButton' style={{ marginRight: "2%" }}>
                    <div onClick={() => scroll(-50)} style={{ Index: '2000', marginTop: '2rem', borderRadius: '50%', borderColor: 'white', backgroundColor: 'white', padding: '8px', boxShadow: '0 10px 6px -6px grey', width: '30px', height: '30px' }}><LeftOutlined style={{ fontSize: '16px', color: ' #00A94E', verticalAlign: 'super' }} /></div>
                  </div>}
                <SecondaryBar className='secondary-bar-wrapper ' show={secondaryOptions && secondaryOptions.length} ref={ref}>
                  {secondaryOptions && secondaryOptions.map((label, i) => {
                    let isTheElemSelected = isTheElemSelectedFunc(label, state.primaryMenus);
                    const labelClass = label.toLowerCase().replace(" ", "-") + '-menu-item';
                    return (
                      <SecondaryBarItem
                        id={label.toLowerCase().split(' ').join('-') + "-" + activeAddress[0]}
                        className={(i === 0 && label !== 'CONFIGURATION' ? `visited ${labelClass}` : `${labelClass} secondary-bar-item ${label.toLowerCase().split(' ').join('-') + "-" + activeAddress[0]} ${isTheElemSelected ? 'visited' : ''}`)}
                        key={i}
                        onClick={e => {
                          //e.stopPropagation();
                          setDomElementToSelected(e, state.primaryMenus, label)
                          setActiveAddress([activeAddress[0], i]);
                          localStorage.setItem('clickOnPrimary', 'false');
                          localStorage.setItem('menuSelected', label.toLowerCase().split(' ').join('-') + "-" + activeAddress[0]);
                        }}
                        selected={activeAddress[1] === i}
                      >
                        {label}
                      </SecondaryBarItem>
                    )
                  })}
                </SecondaryBar>
                {secondaryOptions && (secondaryOptions.length > 3) &&
                  <div className='buttonBar rightButton' style={{ marginLeft: "3%" }}>
                    <div onClick={() => scroll(50)} style={{ zIndex: '2000', marginTop: '2rem', borderRadius: '50%', borderColor: 'white', backgroundColor: 'white', padding: '8px', boxShadow: '0 10px 6px -6px grey', width: '30px', height: '30px' }}><RightOutlined style={{ fontSize: '16px', color: ' #00A94E', verticalAlign: 'super' }} /></div>
                  </div>}
              </div>


              <div className='template-wrapper regular-wapper' style={{ width: 'none', overflowY: "scroll" }}>
                {/*controller?.[activeAddress[0]] && (
                  <Title className="template-title">
                    {controller[activeAddress[0]].label}
                  </Title>
                )}
                {controller?.[activeAddress[0]]?.sections?.[activeAddress[1]] && (
                  <Subtitle className="template-subtitle">
                    {controller[activeAddress[0]]?.sections?.[activeAddress[1]].label}
                  </Subtitle>
                )*/}
                <Accordion
                  sections={
                    controller[activeAddress[0]]?.sections?.[activeAddress[1]]
                      ?.sections
                  }
                  configuration={configuration}
                  handleSetAttribute={handleSetAttribute}
                />
              </div>
              <div className='template-wrapper summary-wrapper' >
                {controller?.[activeAddress[0]]?.sections?.[activeAddress[1]] && (
                  <Subtitle className="template-subtitle">
                    {controller[activeAddress[0]]?.sections?.[activeAddress[1]].label}
                  </Subtitle>
                )}
                <div className='configuration-wrapper'>
                  {newConfiguration.map((item, index) => (
                    <div
                      data-class={gearHeader(item)}
                      key={index}
                      className={`group configuration-${gearHeaderClass.toLowerCase()}`}>
                      {(groupTitle !== item.optionGroup) && item.optionGroup !== 'COAT TRIM' && item.optionGroup !== 'PANT TRIM' ? <div className='header-gear'>{gearHeaderTitle}</div> : null}
                      {
                        !(item.optionGroup === 'COAT TRIM' && (newConfiguration[0].optionDescription === 'Pants' || newConfiguration[0].optionDescription === 'GPS Pant')) &&
                          !(item.optionGroup === 'PANT TRIM' && newConfiguration[0].optionDescription === 'Jacket') &&
                          groupTitle !== item.optionGroup && ifExistSection(item.optionGroup, gearTitle) === false ?
                          <div className='group-header'>{item.optionGroup}</div> : null}
                      {
                        !(item.optionGroup === 'COAT TRIM' && (newConfiguration[0].optionDescription === 'Pants' || newConfiguration[0].optionDescription === 'GPS Pant')) &&
                          !(item.optionGroup === 'PANT TRIM' && newConfiguration[0].optionDescription === 'Jacket') &&
                          groupTitle !== item.optionGroup ?
                          <div className='group-content'>
                            <table className='configuration-table'>
                              <tbody>{configurationSection(item.optionGroup)}{html.map((item, index) => (item))}
                              </tbody>
                            </table>
                          </div> : null}
                    </div>
                  ))}
                </div>

                <div className='lettering-wrapper'>
                  <div className="summary--padding" >
                    <span style={{ color: "#57585A", display: 'flex', justifyContent: 'center' }}>Images displayed are for illustration purposes only. Actual product may vary.
                      <a href="https://globe.msasafety.com/configurator/support" target="_blank" style={{ marginLeft: '0.7rem' }}> Learn more</a>
                    </span>

                  </div>
                  {letteringConfiguration.map((item, index) => (
                    <div key={index}>
                      {item.letteringText !== null && item.letteringText !== '' ?
                        <div key={index} className='group'>
                          <div className='group-header'>{item.letteringGroup}</div>
                          <div className='group-content'>
                            <div className='group-content-row'>
                              <div>{item.letteringText}</div>
                              <div>{item.letteringColor}</div>
                              <div>{item.letteringSize}</div>
                              {item.letteringArcheting ? <div>{item.letteringArcheting}</div> : ''}
                            </div>
                          </div>
                        </div> : null}
                    </div>
                  ))}
                </div>

                <div className='submit-wrapper'>
                  <div className="summary--padding" >
                    <span style={{ color: "#57585A", display: 'flex', justifyContent: 'center' }}>Images displayed are for illustration purposes only. Actual product may vary.
                      <a href="https://globe.msasafety.com/configurator/support" target="_blank" style={{ marginLeft: '0.7rem' }}> Learn more</a>
                    </span>
                    <span style={{ color: "#57585A", display: 'flex', justifyContent: 'center' }}><span style={{ color: 'red' }}>*</span>Swipe down and submit your request</span>
                  </div>

                  <div className='group'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='group-header'>User Information</div>
                      <div className='group-content'>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>First Name:</label>
                            <input name="firstName" ref={register({ required: true })} />
                            {errors.firstName && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>Last Name:</label>
                            <input name="lastName" ref={register({ required: true })} />
                            {errors.lastName && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>Company Name:</label>
                            <input name="companyName" ref={register({ required: true })} />
                            {errors.companyName && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>Street Address:</label>
                            <input name="streetAddress" ref={register({ required: true })} />
                            {errors.streetAddress && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>City:</label>
                            <input name="city" ref={register({ required: true })} />
                            {errors.city && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>State:</label>
                            <input name="state" ref={register({ required: true })} />
                            {errors.state && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>Country:</label>
                            <input name="country" ref={register({ required: true })} />
                            {errors.country && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label>Postal Code:</label>
                            <input name="postalCode" ref={register({ required: true })} />
                            {errors.postalCode && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>
                      </div>

                      <div className='group-header'>Quote Information</div>
                      <div className='group-content'>
                        <div className='group-content-row'>
                          <div className='submit-row'>
                            <label className='form-input-label'>Product  Quantity:</label>
                            <div className='group-content-input'>
                              {(gearSelected === 'Jacket' || gearSelected === 'GX3' || gearSelected === 'GX3 Coat' || gearSelected === 'Athletix') ?
                                <div>
                                  <input name="productQuantityJacket" min="0" step="1" className='form-input-group' type="number" defaultValue={0} ref={register({ required: true, min: 1, valueAsNumber: true })} />
                                  <span className=''>
                                    <strong>JACKET</strong>
                                  </span>
                                </div>
                                : null
                              }
                              {(gearSelected === 'Jacket' || gearSelected === 'GX3' || gearSelected === 'GX3 Coat' || gearSelected === 'Athletix')
                                && errors.productQuantityJacket && <span className='form-group-message-error'>This field is required and cannot be less than 0</span>}

                              {(gearSelected === 'Pants' || gearSelected === 'GX3' || gearSelected === 'GPS Pant' || gearSelected === 'Athletix') ?
                                <div>
                                  <input name="productQuantityPants" min="0" step="1" className='form-input-group input-pants' type="number" defaultValue={0} ref={register({ required: true, min: 1, valueAsNumber: true })} />
                                  <span className=''><strong>PANTS</strong></span>
                                </div>
                                : null}

                              {(gearSelected === 'Pants' || gearSelected === 'GX3' || gearSelected === 'GPS Pant' || gearSelected === 'Athletix')
                                && errors.productQuantityPants && <span className='form-group-message-error'>This field is required and cannot be less than 0</span>}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='group-header'>Recipient(s)</div>
                      <div className='group-content'>
                        {/* <div className='group-content-row'>
                            <div className='submit-row'>
                              <input name="globeCustomerService" className='form-checkbox-input' type="checkbox" ref={register()} />
                              <label className='form-checkbox-label'>Globe Customer Service</label>
                              {/* {errors.quoteName && <span className='form-message-error'>This field is required</span>} 
                            </div>
                        </div> */}
                        <div className='group-content-row content-email'>
                          <div className='submit-row'>
                            <label>Recipient Email(s):</label>
                            <input name="recipientEmail" type="email" ref={register({ required: true })} />
                            {errors.recipientEmail && <span className='form-message-error'>This field is required</span>}
                          </div>
                        </div>

                      </div>

                      <div className='content-submit'>
                        <input className='form-submit' type="submit" />
                      </div>

                    </form>
                  </div>

                  <Modal
                    ariaHideApp={false}
                    isOpen={configurationListModalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel=""
                  >

                    {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
                    <div className='modal-message modal-message-configuration-list'>
                      <div className='modal-title'>{modalMessage}</div>
                      <div className="search-configuration">
                        <input 
                          type = "search" 
                          placeholder = "Search Configuration" 
                          onChange = {handleSearchConfiguration}
                        />
                      </div>
                        {buttonsConfiguration === true ?
                          <ul className='configuration-list'>
                            { configurationList.map((conf, i) => {
                              return <li onClick={() => loadConfiguration(conf.value["Configuration ID"]) } key={i}><a href='#'>{conf.value['Configuration Name']}</a></li>
                            })}
                          </ul>
                          : null}
                    </div>
                  </Modal>
                </div>

                <div className='save-configuration-wrapper'>
                  {controller?.[activeAddress[0]]?.sections?.[activeAddress[1]].label === "SAVE CONFIGURATION" ?
                    <Modal
                      ariaHideApp={false}
                      isOpen={configurationNameModal}
                      onRequestClose={configurationNameCloseModal}
                      style={customStyles}
                      contentLabel=""
                    >
                      <div className='configuration-list-save'>
                        <label>Configuration Name: </label>
                        <input name="configurationName" className='input-text-configuration-name' value={configurationName} onChange={e => { configurationNameInputChange(e) }} ref={register({ required: true })} />

                        <div className='configuration-name-group-buttons' >
                          <button className='button-modal button-yes' onClick={saveConfigurationName} >Save</button>
                          <button className='button-modal' onClick={configurationNameCloseModal}>Cancel</button>
                        </div>
                      </div>
                    </Modal>
                    : null}
                </div>

              </div>
            </div>
            : <div><div> <ClipLoader size={155} css={override} color={'#00A94E'} /></div><div className="Loader" style={{ marginLeft: '50px' }}><h2>Loading...</h2></div></div>

          }
        </Wrapper>
      </div>

      <footer>
        <p style={{ textAlign: "center" }}>{`© ${currentYear.getFullYear()}  Globe Holding Company, LLC . All rights reserved. |  `}<a className="link" href="https://globe.msasafety.com/configurator/support">Support</a></p>
      </footer>
    </div>
  );
};

export default AccordionTemplate2;
