import axios from 'axios';

const HOST = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

export const saveConfiguration = async (body) => {
  await axios
    .post(`${HOST}/saveConfiguration`, {
        body
    })
    .then((res) => {
      const productSelected = window.location.pathname == "/athletix" ? "Athletix" : "GX3";
      if(res.data !== undefined && res.data.status !== 500 ){
        localStorage.setItem(`shortId_${productSelected}`,res.data.shortId);
      }
      
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};


export const addNewConfigurationInDataTable = async (body) => {
  await axios
    .post(`${HOST}/addNewConfigurationInDataTable`, {
        body
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDataTable = async () => {
  await axios
    .get(`${HOST}/getDataTable`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};